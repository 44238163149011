import React from "react";
// import { useLocation } from "@reach/router"; // this helps tracking the location

import Footer from "./Footer";
import { Navigation } from "./Navigation";

export default function Layout({ intl, id, children }) {
  return (
    <>
      <Navigation intl={intl} />
      <div id={id}>{children}</div>
      <Footer intl={intl} />
    </>
  );
}
