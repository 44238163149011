import React, { Fragment } from "react";
import { Link, FormattedMessage, changeLocale } from "gatsby-plugin-react-intl";

import { Popover, Transition } from "@headlessui/react";
import {
  MenuIcon,
  PhoneIcon,
  ViewGridIcon,
  XIcon,
  FilterIcon,
  GlobeIcon,
  OfficeBuildingIcon,
  UserGroupIcon,
  NewspaperIcon,
  // AcademicCapIcon,
  MailIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";

import Flags from "country-flag-icons/react/3x2";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function Navigation({ intl }) {
  const features = [
    {
      name: intl.formatMessage({ id: "asset-data.menu-item", defaultMessage: "Property information extraction" }),
      href: "/asset-data",
      description: intl.formatMessage({ id: "asset-data.menu-item-description", defaultMessage: "We fill data gaps using space technology and AI" }),
      icon: OfficeBuildingIcon,
    },
    {
      name: intl.formatMessage({ id: "satellite-powered-leads.menu-item", defaultMessage: "Satellite-powered Lead Generation" }),
      href: "/satellite-powered-leads",
      description: intl.formatMessage({ id: "satellite-powered-leads.menu-item-description", defaultMessage: "Improve your conversion rates" }),
      icon: FilterIcon,
    },
    {
      name: intl.formatMessage({ id: "neighborhood-insights.menu-item", defaultMessage: "Neighborhood Insights" }),
      href: "/neighborhood-insights",
      description: intl.formatMessage({
        id: "neighborhood-insights.menu-item-description",
        defaultMessage: "Profile neighborhoods and understand local urban trends",
      }),
      icon: ViewGridIcon,
    },
    {
      name: intl.formatMessage({ id: "satellite-imagery.menu-item", defaultMessage: "Customized Satellite Image Viewer" }),
      href: "/satellite-imagery",
      description: intl.formatMessage({
        id: "satellite-imagery-description.menu-item",
        defaultMessage: "Google Maps pictures are not actual enough? We've got a solution.",
      }),
      icon: GlobeIcon,
    },
  ];
  const callsToAction = [
    { name: intl.formatMessage({ id: "menu.cta.contact-us", defaultMessage: "Contact Us" }), href: "/contact-us", icon: MailIcon },
    // { name: intl.formatMessage({ id: "menu.cta.bookacall", defaultMessage: "Book a Call" }), href: "/book-a-call", icon: PhoneIcon },
  ];
  const about = [
    // {
    //   name: "Company",
    //   description: "A brief description of our history.",
    //   href: "/",
    //   icon: AcademicCapIcon,
    // },
    {
      name: intl.formatMessage({ id: "team.menu-item", defaultMessage: "Team" }),
      description: intl.formatMessage({
        id: "team.menu-item-description",
        defaultMessage: "Meet the people behind ubicube.",
      }),
      href: "/team",
      icon: UserGroupIcon,
    },
    {
      name: intl.formatMessage({ id: "press.menu-item", defaultMessage: "Press and Media" }),
      description: intl.formatMessage({
        id: "press.menu-item-description",
        defaultMessage: "Read what independent entities write about us!",
      }),
      href: "/press",
      icon: NewspaperIcon,
    },
  ];

  const handleChangeLocale = () => {
    if (intl.locale === "en") {
      changeLocale("de", null);
    } else {
      changeLocale("en", null);
    }
  };

  return (
    <nav className="relative bg-gray-50">
      <Popover className="relative bg-white shadow">
        <div className="px-4 sm:px-6">
          <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <Link to="/">
                <span className="sr-only">Ubicube</span>
                <div className="h-8 w-auto sm:h-10">
                  <UbicubeLogo />
                </div>
              </Link>
            </div>
            <div className="-mr-2 -my-2 flex md:hidden">
              <button className="mr-2" onClick={handleChangeLocale}>
                {intl.locale === "en" ? <Flags.DE className="h-4" /> : <Flags.GB className="h-4" />}
              </button>
              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-uc-500">
                <span className="sr-only">
                  <FormattedMessage id="nav.open-menu" defaultMessage="Open Menu" />
                </span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden md:flex space-x-10">
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-gray-900" : "text-gray-500",
                        "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-uc-500"
                      )}
                    >
                      <span>{intl.formatMessage({ id: "menu.solutions-for-real-estate", defaultMessage: "Solutions for Real Estate" })}</span>
                      <ChevronDownIcon
                        className={classNames(open ? "text-gray-600" : "text-gray-400", "ml-2 h-5 w-5 group-hover:text-gray-500")}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute -ml-4 mt-3 transform z-20 px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                            {features.map((item) => (
                              <Link key={item.name} to={item.href} className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
                                <item.icon className="flex-shrink-0 h-6 w-6 text-uc-600" aria-hidden="true" />
                                <div className="ml-4">
                                  <p className="text-base font-medium text-gray-900">{item.name}</p>
                                  <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                </div>
                              </Link>
                            ))}
                          </div>
                          <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                            {callsToAction.map((item) => (
                              <div key={item.name} className="flow-root">
                                <Link
                                  to={item.href}
                                  className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
                                >
                                  <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                                  <span className="ml-3">{item.name}</span>
                                </Link>
                              </div>
                            ))}
                            {/* <div className="flow-root">
                              <a
                                href="https://calendly.com/christian-oswald/ubicube-intro"
                                className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
                              >
                                <PhoneIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                                <span className="ml-3">{intl.formatMessage({ id: "menu.cta.bookacall", defaultMessage: "Book a Call" })}</span>
                              </a>
                            </div> */}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>

              <Link to="/blog" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Blog
              </Link>
              {/* <Link to="/" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Docs
              </Link> */}

              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-gray-900" : "text-gray-500",
                        "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-uc-500"
                      )}
                    >
                      <span>About</span>
                      <ChevronDownIcon
                        className={classNames(open ? "text-gray-600" : "text-gray-400", "ml-2 h-5 w-5 group-hover:text-gray-500")}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute left-1/2 z-20 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                            {about.map((item) => (
                              <Link key={item.name} to={item.href} className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
                                <item.icon className="flex-shrink-0 h-6 w-6 text-uc-600" aria-hidden="true" />
                                <div className="ml-4">
                                  <p className="text-base font-medium text-gray-900">{item.name}</p>
                                  <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                </div>
                              </Link>
                            ))}
                          </div>
                          {/* <div className="px-5 py-5 bg-gray-50 sm:px-8 sm:py-8">
                            <div>
                              <h3 className="text-sm tracking-wide font-medium text-gray-500 uppercase">
                                Recent Posts
                              </h3>
                              <ul className="mt-4 space-y-4">
                                {recentPosts.map((item) => (
                                  <li key={item.id} className="text-base truncate">
                                    <Link
                                      to={item.href}
                                      className="font-medium text-gray-900 hover:text-gray-700"
                                    >
                                      {item.name}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div className="mt-5 text-sm">
                              <Link to="/" className="font-medium text-uc-600 hover:text-uc-500">
                                {" "}
                                View all posts <span aria-hidden="true">&rarr;</span>
                              </Link>
                            </div>
                          </div> */}
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
              <button className="text-base font-bold  text-gray-500 hover:text-gray-900" onClick={handleChangeLocale}>
                {intl.locale === "en" ? <Flags.DE className="h-4" /> : <Flags.GB className="h-4" />}
              </button>
            </Popover.Group>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              <Link
                to="/contact-us"
                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-uc-600 hover:bg-uc-700"
              >
                {intl.formatMessage({ id: "menu.cta.contact-us", defaultMessage: "Contact Us" })}
              </Link>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel focus className="absolute top-0 inset-x-0 z-20 p-2 transition transform origin-top-right md:hidden">
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <img className="h-8 w-auto" src="../ubicube.svg" alt="Ubicube" />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-uc-500">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    {features.map((item) => (
                      <Link key={item.name} to={item.href} className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                        <item.icon className="flex-shrink-0 h-6 w-6 text-uc-600" aria-hidden="true" />
                        <span className="ml-3 text-base font-medium text-gray-900">{item.name}</span>
                      </Link>
                    ))}
                  </nav>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                  {about.map((item) => (
                    <Link key={item.name} to={item.href} className="text-base font-medium text-gray-900 hover:text-gray-700">
                      {item.name}
                    </Link>
                  ))}
                  <Link to="/blog">Blog</Link>
                </div>
                <div>
                  <Link
                    to="/contact-us"
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-uc-600 hover:bg-uc-700"
                  >
                    {intl.formatMessage({ id: "menu.cta.contact-us", defaultMessage: "Contact Us" })}
                  </Link>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </nav>
  );
}

function UbicubeLogo() {
  return (
    <svg height="40px" viewBox="0 0 120 30" id="svg8" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-188.1325 73.3737)">
        <g transform="translate(165.1478 -60.3621) scale(.1395)">
          <path
            style={{
              fill: "none",
              fillOpacity: 1,
              stroke: "#014d4e",
              strokeWidth: 8,
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
              strokeOpacity: 1,
              paintOrder: "normal",
            }}
            d="M168.767 27.32v-74l64.0835-37.0003L296.9342-46.68v74l-64.106 84.869L168.767 27.32"
          />
          <g transform="translate(373.2643 -133.8232)">
            <path
              d="m-99.7748 102.1559-40.6438 23.4994v46.998l40.6438-23.499z"
              style={{
                fill: "#014d4e",
                fillOpacity: 1,
                stroke: "#fff",
                strokeWidth: 4,
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeMiterlimit: 4,
                strokeDasharray: "none",
                strokeOpacity: 1,
              }}
            />
            <path
              d="m-140.4186 78.6569-40.6438 23.499 40.6438 23.4994 40.6438-23.4994z"
              style={{
                fill: "#014d4e",
                fillOpacity: 1,
                stroke: "#fff",
                strokeWidth: 4,
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeMiterlimit: 4,
                strokeDasharray: "none",
                strokeOpacity: 1,
              }}
            />
            <path
              d="m-181.0624 149.1543 40.6438 23.499v-46.998l-40.6438-23.4994v46.9984"
              style={{
                fill: "#03c2c5",
                fillOpacity: 1,
                stroke: "#fff",
                strokeWidth: 4,
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeMiterlimit: 4,
                strokeDasharray: "none",
                strokeOpacity: 1,
              }}
            />
          </g>
        </g>
        <text
          xmlSpace="preserve"
          style={{
            fontStyle: "italic",
            fontSize: "22.5778px",
            lineHeight: 1.25,
            fontFamily: "sans-serif",
            InkscapeFontSpecification: "'sans-serif Italic'",
            fill: "#014d4e",
            fillOpacity: 1,
            strokeWidth: 0.264583,
          }}
          x={216.1009}
          y={-49.749}
        >
          <tspan
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              fontStretch: "normal",
              fontSize: "22.5778px",
              fontFamily: "Ubuntu",
              InkscapeFontSpecification: "Ubuntu",
              fill: "#014d4e",
              fillOpacity: 1,
              strokeWidth: 0.264583,
            }}
            x={216.1009}
            y={-49.749}
          >
            {"ubicube"}
          </tspan>
        </text>
      </g>
    </svg>
  );
}
