import React from "react";
import { Link } from "gatsby-plugin-react-intl";

export default function Footer({ intl }) {
  const navigation = {
    main: [
      { name: intl.formatMessage({ id: "nav.team", defaultMessage: "Team" }), href: "/team" },
      { name: intl.formatMessage({ id: "nav.blog", defaultMessage: "Blog" }), href: "/blog" },
      { name: intl.formatMessage({ id: "nav.press", defaultMessage: "Press" }), href: "/press" },
      { name: intl.formatMessage({ id: "nav.imprint", defaultMessage: "Imprint" }), href: "/imprint" },
      { name: intl.formatMessage({ id: "nav.contactus", defaultMessage: "Contact us" }), href: "/contact-us" },
    ],
  };

  const footerDate = new Date().getFullYear();

  return (
    <footer className="bg-gray-50">
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
      </div>
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          {navigation.main.map((item) => (
            <div key={item.name} className="px-5 py-2">
              <Link to={item.href} className="text-base text-gray-500 hover:text-gray-900">
                {item.name}
              </Link>
            </div>
          ))}
        </nav>
        <div className="mt-8 flex justify-center space-x-6">
          <a href="https://www.linkedin.com/company/ubicube/" className="text-gray-400 hover:text-gray-500" target="_blank" rel="noreferrer">
            <span className="sr-only">LinkedIn</span>
            <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                clipRule="evenodd"
              />
            </svg>
          </a>
        </div>
        <p className="mt-8 text-center text-base text-gray-400">&copy; {footerDate} ubicube</p>
      </div>
    </footer>
  );
}
